/**
 *  A mixin that provides debounced async validation, that is, validation that must be performed
 *  through some sort of server request (checking whether a user exists, validating a writing
 *  challenge, etc.).
 *
 *  Note that, to set up this validation, you have to call "this.initiateDebouncedValidation()"
 *  to begin the debounce timer. It's probably best to do this whenever an input event occurs.
 *
 *  This also requires an implementation of a validate() function!
 *
 *  TODO: this shouldn't require the validate function. We should implement it here or remove it.
 */
export const asyncValidationMixin = {
  data() {
    return {
      asyncErrorMessage: '',
      asyncErrorOccurred: false,
      asyncValidationSuccessful: false,
      asyncErrorIssues: [],
      debounceTimerId: null,
      validatingAsync: false,
      debounceTime: 1000,
    };
  },
  emits: ['validationSucceeded', 'validationFailed'],
  props: {
    asyncValidationFnc: Function,
  },
  unmounted() {
    this.clearDebounceTimer();
  },
  methods: {
    clearDebounceTimer() {
      if (this.debounceTimerId) {
        clearTimeout(this.debounceTimerId);
      }
    },
    initiateDebouncedValidation() {
      this.asyncValidationSuccessful = false;
      this.asyncErrorOccurred = false;
      this.clearDebounceTimer();

      this.debounceTimerId = setTimeout(() => {
        if (this.modelValue.length > 0) {
          this.performAsyncValidation(this.modelValue);
        }
      }, this.debounceTime);
    },
    performAsyncValidation() {
      this.validatingAsync = true;

      this.asyncValidationFnc(this.modelValue)
        .then(response => {
          this.asyncErrorOccurred = false;
          this.asyncValidationSuccessful = true;

          // Validation failure doesn't always return an actual HTTP error.
          // When we validate a round submission, for example, we will return a 200 even if the challenge doesn't
          // meet the challenge criteria (since the request itself didn't fail). In this case, we check for the
          // success property and set our issues array accordingly
          if (!response.success && response.issues) {
            this.asyncErrorIssues = response.issues;
            this.asyncValidationSuccessful = false;
            this.asyncErrorOccurred = true;
          } else {
            this.asyncErrorOccurred = false;
            this.asyncValidationSuccessful = true;
          }
        })
        .catch(errorMessage => {
          this.asyncErrorOccurred = true;
          this.asyncValidationSuccessful = false;
          this.asyncErrorMessage = errorMessage;
        })
        .finally(() => {
          this.validatingAsync = false;
          this.validate();
        });
    },
  },
};
