/**
 *  A mixin which adds the ability to prompt the user about unsaved changes (using the browser's native
 *  beforeunload functionality).
 *
 *  Note that you have to call this.markComponentDirty() at some point within your component to actually
 *  trigger the prompt.
 *
 *  TODO: this approach only works with actual redirections, that is, it will trigger when the user
 *  refreshes the page or tries to navigate to google.com, but it won't trigger if the user
 *  clicks a router link within the app. We need a route guard for that.
 */

import unsavedChangesStateStore from '../services/unsavedChangesStateStore';

export const unsavedWorkMixin = {
  unmounted() {
    this.removeBeforeUnloadListener();
  },
  methods: {
    markComponentDirty() {
      window.addEventListener('beforeunload', this.promptUserAboutUnsavedWork);
      unsavedChangesStateStore.setUnsavedChanges(true);
    },
    markComponentClean() {
      unsavedChangesStateStore.setUnsavedChanges(false);
      this.removeBeforeUnloadListener();
    },
    removeBeforeUnloadListener() {
      window.removeEventListener('beforeunload', this.promptUserAboutUnsavedWork);
    },
    promptUserAboutUnsavedWork(event) {
      // This is the proper way to trigger an unsaved work prompt, so we disable the no-param-reassign eslint rule here
      event.preventDefault(); // eslint-disable-line no-param-reassign
      event.returnValue = ''; // eslint-disable-line no-param-reassign
    },
  },
};
