<template>
  <label>
    <div>{{ label }}</div>
    <div class="gradient-border" aria-hidden="true"></div>
    <div class="textarea-wrapper">
      <div class="error-overlay" ref="overlay"></div>
      <transition name="validating">
        <SmallLoadingSpinner v-if="validatingAsync | loading" />
      </transition>
      <transition name="success">
        <SuccessIcon v-if="asyncValidationSuccessful" />
      </transition>
      <textarea
        :placeholder="placeholder"
        :class="{ error: limitExceeded, 'async-validation': asyncValidationFnc }"
        spellcheck="true"
        @input="handleInput($event.target.value)"
        :required="isBlankable"
        :value="modelValue"
        :disabled="disabled || loading"
      />
    </div>
    <div class="character-count" :class="{ error: limitExceeded }">
      <span>{{ characterCount }} / {{ maxLength }}</span>
    </div>
    <transition name="error-explanation">
      <div
        v-if="showErrorExplanation"
        class="error-explanation"
        role="tooltip"
        :style="errorExplanationStyle"
      >
        {{ errorExplanationToDisplay }}
      </div>
    </transition>
  </label>
</template>
<script>
import { asyncValidationMixin } from '../mixins/asyncValidation';
import SmallLoadingSpinner from './SmallLoadingSpinner.vue';
import SuccessIcon from './SuccessIcon.vue';

export default {
  name: 'MysticalTextArea',
  mixins: [asyncValidationMixin],
  components: {
    SmallLoadingSpinner,
    SuccessIcon,
  },
  data() {
    return {
      characterCount: 0,
      showErrorExplanation: false,
      errorExplanationToDisplay: '',
      errorExplanationX: 0,
      errorExplanationY: 0,
      errorExplanationTopOffsetPx: 50,
    };
  },
  emits: ['update:modelValue'],
  props: {
    label: String,
    maxLength: {
      type: Number,
      default: 256,
    },
    placeholder: String,
    isBlankable: {
      type: Boolean,
      default: true,
    },
    modelValue: String,
    loading: {
      type: Boolean,
      default: false
    },
    disabled: Boolean
  },
  watch: {
    asyncErrorIssues(newAsyncErrors) {
      if (newAsyncErrors.length > 0) {
        this.setOverlayContents(this.modelValue);
      } else {
        this.clearErrorOverlay();
      }
    },
    modelValue(newValue) {
      this.handleInput(newValue);
    }
  },
  methods: {
    validate() {
      if (this.modelValue.length > 0 && !this.limitExceeded && this.asyncValidationSuccessful) {
        this.$emit('validationSucceeded');
      } else {
        this.$emit('validationFailed');
      }
    },
    handleInput(value) {
      this.clearErrorOverlay();

      if (this.asyncValidationFnc) {
        this.initiateDebouncedValidation();
      }

      this.characterCount = value.length;
      this.$emit('update:modelValue', value);
    },
    // TODO: refactor!
    setOverlayContents(value) {
      this.clearErrorOverlay();
      let indexToPrint = 0;
      const errorsToPrint = [...this.asyncErrorIssues];

      for (let i = 0; i < errorsToPrint.length; i++) {
        const nextOkayPortion = value.substring(indexToPrint, errorsToPrint[i].startIndex);
        const nextErrorPortion = value.substring(
          errorsToPrint[i].startIndex,
          errorsToPrint[i].endIndex
        );

        const nextOkaySpan = document.createElement('span');
        nextOkaySpan.classList.add('okay');
        nextOkaySpan.style.color = 'transparent';
        nextOkaySpan.innerText = nextOkayPortion;

        const nextErrorSpan = document.createElement('span');
        nextErrorSpan.classList.add('error');
        nextErrorSpan.style.background = 'red';
        nextErrorSpan.style.borderRadius = '0.5rem';
        nextErrorSpan.style.color = 'white';
        nextErrorSpan.style.pointerEvents = 'all';
        nextErrorSpan.innerText = nextErrorPortion;

        nextErrorSpan.addEventListener('mouseenter', event => {
          this.errorExplanationX = event.x;
          this.errorExplanationY = event.y - this.errorExplanationTopOffsetPx;
          this.showErrorExplanation = true;
          this.errorExplanationToDisplay = errorsToPrint[i].failureReason;
        });
        nextErrorSpan.addEventListener('mouseleave', () => {
          this.showErrorExplanation = false;
        });

        this.$refs.overlay.appendChild(nextOkaySpan);
        this.$refs.overlay.appendChild(nextErrorSpan);

        indexToPrint = errorsToPrint[i].endIndex;
      }

      if (indexToPrint < value.length) {
        const nextOkaySpan = document.createElement('span');
        nextOkaySpan.classList.add('okay');
        nextOkaySpan.innerText = value.substring(indexToPrint);
        nextOkaySpan.style.color = 'transparent';
        this.$refs.overlay.appendChild(nextOkaySpan);
      }
    },
    clearErrorOverlay() {
      this.removeAllNodes(this.$refs.overlay);
    },
    removeAllNodes(element) {
      while (element.firstChild) {
        element.removeChild(element.firstChild);
      }
    },
  },
  computed: {
    limitExceeded() {
      return this.characterCount > this.maxLength;
    },
    errorExplanationStyle() {
      return `top: ${this.errorExplanationY}px; left: ${this.errorExplanationX}px;`;
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  display: block;
  font-size: 2rem;
  margin-top: 2rem;
  width: 100%;
  position: relative;

  .textarea-wrapper {
    position: relative;
    background: var(--color-graphite);
    height: 20rem;

    .spinner,
    .async-validation-success-indicator {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }

    textarea,
    .error-overlay {
      font-size: 1.8rem;
      padding: 1rem;
      padding-right: 4.3rem;
      letter-spacing: 0px;
    }

    .error-overlay {
      position: absolute;
      pointer-events: none;
    }

    .async-validation-success-indicator {
      width: 3rem;
      height: 3rem;
    }

    textarea {
      display: block;
      width: 100%;
      color: white;
      height: 100%;
      background: transparent;
      border: none;
      resize: none;
      margin-top: 0;
      font-family: Nunito;
      transition: color 400ms ease;
      outline: none;
      box-sizing: border-box;
      padding-bottom: 5rem;

      &.error {
        color: var(--color-red);
      }

      &:disabled {
        opacity: .5;
      }

      &.async-validation {
        padding-right: 4.3rem;
      }
    }
  }

  .gradient-border {
    margin-top: 1rem;
    height: 0.4rem;
    background: linear-gradient(
      to right,
      #4a8af7,
      #2ed4bf,
      #d7e615,
      #fa992d,
      #e55f83,
      #d941bb,
      #9b4dde,
      #4a8af7
    );
  }

  .character-count {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    transition: color 400ms ease;
    background: var(--color-graphite-lighten);
    padding: 0.5rem 1rem;
    border-radius: 2.5rem;
    font-size: 1.5rem;

    &.error {
      color: var(--color-red);
    }
  }

  .error-explanation {
    position: fixed;
    padding: 1rem;
    background: var(--color-purple-darken-2);
    border-radius: 1rem;
    font-size: 1.5rem;
    box-shadow: var(--dark-shadow);
  }
}

.validating-enter-active,
.validating-leave-active {
  transition: opacity 400ms ease;
}

.validating-enter-from,
.validating-leave-to {
  opacity: 0;
}

.success-enter-active,
.success-leave-active {
  transition: opacity 400ms ease, transform 600ms ease;
}

.success-enter-from,
.success-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}

.error-explanation-enter-active,
.error-explanation-leave-active {
  transition: opacity 400ms ease, transform 400ms ease;
}

.error-explanation-enter-from,
.error-explanation-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}
</style>
